import {
  getBrandsByIds as getBrandsByIdsFunc,
  getBrandsWithProductsByIds,
} from '@/lib/api.brands';
import { Image } from '@/types/catalog';
import { mapResult } from '@/utils/typings/result';
export interface IBrandWithProductsVm {
  id: string;
  name: string;
  slug: string | undefined;
  totalProductsNumber: number;
  products_image: Image[];
  markup: number | undefined;
  delivery_time: number | undefined;
  featured_image_url?: string | undefined;
  short_description?: string | undefined;
}

export interface IBrandCard {
  id: string;
  name: string;
  slug: string | undefined;
  image: string | undefined;
}

const getBrandWithProducts = async (ids: string[]) => {
  const brandWithProductsPm = await getBrandsWithProductsByIds(ids);

  return mapResult(brandWithProductsPm, brandWithProductsPm => {
    const brandWithProductsVm: IBrandWithProductsVm[] = brandWithProductsPm.map(
      brand => {
        return {
          id: brand.id,
          name: brand.name,
          slug: brand.name_slug,
          totalProductsNumber: brand.totalProductsNumber,
          products_image: brand.product_images,
          markup: brand.markup,
          delivery_time: brand.delivery_time,
          featured_image_url: brand.featured_image_url,
          short_description: brand.short_description,
        };
      }
    );
    return brandWithProductsVm;
  });
};

const getBrandForCard = async (ids: string[]) => {
  const brandPm = await getBrandsByIds(ids);

  return mapResult(brandPm, brandPm => {
    const brandCardVm: IBrandCard[] = brandPm.data.map(brand => {
      return {
        id: brand.id,
        name: brand.name,
        slug: brand.name_slug,
        image: brand.featured_image_url,
      };
    });
    return brandCardVm;
  });
};

const getBrandsByIds = async (ids: string[]) => {
  const brandPm = await getBrandsByIdsFunc(ids);
  return brandPm;
};

// class BrandViewModel {
//   async getBrandWithProducts(ids: string[]): Promise<IBrandWithProductsVm[]> {
//     const brandWithProductsPm = await getBrandsWithProductsByIds(ids);
//     const brandWithProductsVm = brandWithProductsPm.map(brand => {
//       return {
//         id: brand.id,
//         name: brand.name,
//         slug: brand.name_slug,
//         totalProductsNumber: brand.totalProductsNumber,
//         products_image: brand.product_images,
//         markup: brand.markup,
//         delivery_time: brand.delivery_time,
//       };
//     });
//     return brandWithProductsVm;
//   }

//   async getBrandsByIds(ids: string[]) {
//     const brandPm = await getBrandsByIds(ids);
//     return brandPm;
//   }

//   async getBrandForCard(ids: string[]): Promise<IBrandCard[]> {
//     const brandPm = await getBrandsByIds(ids);
//     const brandCardVm = brandPm.data.map(brand => {
//       return {
//         id: brand.id,
//         name: brand.name,
//         slug: brand.name_slug,
//         image: brand.featured_image_url,
//       };
//     });
//     return brandCardVm;
//   }
// }

export const brandViewModel = {
  getBrandWithProducts,
  getBrandForCard,
  getBrandsByIds,
};

// export default BrandViewModel;
