import { AppError } from '@/utils/typings/errors';
import { isOk, ok, Result } from '@/utils/typings/result';
import { Dispatch, SetStateAction, useState } from 'react';

type ResultHookReturn<R, E> =
  | {
      status: 'ok';
      data: R;
      error: never;
    }
  | {
      status: 'error';
      data: never;
      error: E;
    };

type ResultHookReturnWithEmpty<R, E> =
  | ResultHookReturn<R, E>
  | {
      status: 'empty';
      data: never;
      error: never;
    };

export function useResult<R, E = AppError>(): [
  ResultHookReturnWithEmpty<R, E>,
  Dispatch<SetStateAction<Result<R, E>>>
];
export function useResult<R, E = AppError>(
  initialState: R
): [ResultHookReturn<R, E>, Dispatch<SetStateAction<Result<R, E>>>];
export function useResult<R, E = AppError>(
  initialState?: R
): [ResultHookReturnWithEmpty<R, E>, Dispatch<SetStateAction<Result<R, E>>>] {
  const [state, setState] = useState<Result<R, E> | null>(
    typeof initialState === 'undefined' ? null : ok(initialState)
  );

  const getState = (): ResultHookReturnWithEmpty<R, E> => {
    if (state === null) {
      return {
        status: 'empty',
      } as ResultHookReturnWithEmpty<R, E>;
    }

    if (isOk(state)) {
      return {
        status: 'ok',
        data: state.result,
      } as ResultHookReturnWithEmpty<R, E>;
    }

    return {
      status: 'error',
      error: state.error,
    } as ResultHookReturnWithEmpty<R, E>;
  };

  return [getState(), setState as Dispatch<SetStateAction<Result<R, E>>>];
}

export function foldHookResult<R, E, T>(
  result: ResultHookReturn<R, E>,
  handlers: { onOk: (value: R) => T; onErr: (error: E) => T }
): T;
export function foldHookResult<R, E, T>(
  result: ResultHookReturnWithEmpty<R, E>,
  handlers: { onOk: (value: R) => T; onErr: (error: E) => T; onEmpty: () => T }
): T;
export function foldHookResult<R, E, T>(
  result: ResultHookReturnWithEmpty<R, E>,
  handlers: { onOk: (value: R) => T; onErr: (error: E) => T; onEmpty?: () => T }
): T {
  if (result.status === 'empty') {
    return handlers.onEmpty!();
  }

  if (result.status === 'ok') {
    return handlers.onOk(result.data);
  }

  return handlers.onErr(result.error);
}
